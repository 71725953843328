.products_accordion {

    .accordeon--enlarge {
        width: calc(100% + 20px);
	    margin-left: -10px;
    }

    .grey_bg_row {
        background-color: #f4f4f4;
    }

    h4 {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: .5px;


        &:hover {
            background: #41B6E6;
        }

        i {
            font-size: 12px;
        }

    }
    .button.button--secondary.button--filled,
    .button.button--secondary.button--filled:hover{
        background: #41B6E6;
    }

    .binect-accordion__title:after,
    .binect-accordion__item[open] .binect-accordion__title:after {
        background-image: none !important;
        padding-top: 0 !important;
    }
    &.products__administrationfunctions,
    &.products__fullfilment,
    &.products__models {
        .accordeon_table--row:nth-child(1) { order:1}
        .accordeon_table--row:nth-child(2) { order:2}
        .accordeon_table--row:nth-child(3) { order:6}
        .accordeon_table--row:nth-child(4) { order:4}
        .accordeon_table--row:nth-child(5) { order:3}
        .accordeon_table--row:nth-child(6) { order:5}
    }
}

.products_page {
    .elementor-element.elementor-widget-call-to-action .elementor-cta .elementor-cta__button.elementor-button{
        background: #97D700;
    }
}

.has-no-pad {
    .binect-accordion__title:after,
    .binect-accordion__item[open] .binect-accordion__title:after {
        background-image: none;
	    padding-top: 0 !important;
    }

    summary {
        padding-left: 10px !important;
    }

    h4 {
        font-size: .75rem;
    }

    .elementor-icon{
        i {
            position: static;
	        padding-top: 1rem;

            &:before {
                font-size: 1.5rem;
            }
        }
    }

    .binect-accordion__title {
        display: flex;
	    justify-content: center;
        padding: 1em 0 1.5rem 0;
    }
}

.accordeon_table {
    display: flex;
	align-items: center;
	text-align: center;

    > div {
        position: relative;

        &:first-of-type {
            text-align: left;
        }
    }

    &--row {
        flex: 1;
	    padding: 10px;
    }
}

.grey_bg > div > div > div {
    background-color: #f4f4f4;
}
