.header {
    &--reduced {
        padding: 2rem 0;
    }

    &--main {
        @include media-breakpoint-down(md) {
            padding: 1rem 0;
            background-color: $white;
            min-height: $mobile-menu-height;
            display: flex;
            align-items: center;

            > .container {
                max-width: none;
            }

            .logo--header {
                max-width: 13rem;
            }
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 4rem;
        }

        .logo--header {
            img {
                width: 150px;

                @media screen and (min-width: 442px) {
                    width: 300px;
                }
            }
        }

        .hide--desktop {
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        .header {
            &__nav {
                &--add {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;

                        > ul.menu {
                            width: auto;
                            padding: .45rem 1.5rem;
                            background-color: $primary;
                            border-bottom-left-radius: $border-radius-default;
                            border-bottom-right-radius: $border-radius-default;
                            margin-right: 30px;

                            &:last-of-type {
                                margin-right: 0;
                                background-color: #53565a;
                            }

                            li {
                                color: $white;

                                + li {
                                    margin-left: 1.5rem;
                                }

                                a, i {
                                    color: inherit;
                                }

                                i {
                                    margin-right: 5px;
                                }
                            }

                        }
                    }
                }

                // The main menu (Quicklinks)
                &--main {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .header-link {
                        text-align: left;
                        font-size: 16px;
                        letter-spacing: .5px;

                        .icon {
                            min-width: 20px;
                            display: inline-block;
                        }
                    }

                    .menu__button {
                        margin-top: 50px;
                        text-align: center;

                        a {
                            color: $white;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: .5px;
                            margin-left: auto;
                            margin-right: auto;
                            text-align: center;
                        }
                    }

                    .header__social {
                        border-top: 1px solid $white;
                        padding-top: 20px;
                        margin-top: 20px;

                        &--icon {
                            margin-right: 24px;
                        }
                    }
                }

                // The main menu (Quicklinks)
                &--simplified {
                    width: 100%;
                    min-height: 6rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }


            }

            &__mobile-menu-button {
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            &__mobile-contact-button {
                @include media-breakpoint-up(lg) {
                    display: none;
                }
                @include media-breakpoint-down(lg) {
                    margin-bottom: 3rem;
                    margin-left: 1rem;
                    max-width: calc(100vw - 25%);
                }
            }

            &__phone-icon {
                padding-right: 20px;
                display: inline-block;
                color: $primary;
                font-size: 30px;

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            &__nav-wrapper {

                .menu-hauptmenue-container {
                    @include media-breakpoint-down(md) {
                        position: fixed;
                        top: $mobile-menu-height;
                        left: 0;
                        width: 100vw;
                        height: calc(100vh - #{$mobile-menu-height});
                        background-color: $primary;
                        background-image: url('../images/newsletter-bg.jpg');
                        background-size: cover;
                        background-position: bottom right;
                        background-repeat: no-repeat;
                        z-index: 99;
                        padding: 2rem 0 $mobile-menu-height;
                        display: none;
                        overflow: scroll;

                        &.is-active {
                            display: block;
                            + .header__contact {
                                display: block;
                            }
                        }

                        ul.header__menu {
                            display: block;
                        }
                    }
                }
            }

            &__menu {

                // The main <ul> generated by WordPress
                &--main {
                    position: relative;
                    margin-top: 2rem;

                    @include media-breakpoint-up(xl) {
                        margin-left: -2rem;
                        margin-right: -2rem;
                    }

                    @include media-breakpoint-down(lg) {
                        margin-left: -1rem;
                        margin-right: -1rem;
                    }

                    @include media-breakpoint-down(md) {
                        margin: 0;
                        padding-left: 1em;
                        padding-right: 1em;
                    }

                    > li {
                        // Show the chevron icon on hover
                        &:hover > a:before {
                            content: '';
                            display: inline-block;
                            position: absolute;
                            top: calc(100% - 4px);
                            left: 2rem;
                            width: calc(55% - 2rem);
                            height: 4px;
                            background-color: $primary;
                        }

                        > ul.sub-menu > li > a:before {
                            content: $icon--arrow-right;
                            font-family: $icomoon-font-family;
                            font-size: .5rem;
                            font-weight: normal;
                            color: inherit;
                            margin-right: .625rem;
                        }
                    }

                     > li {
                        margin: 0;
                        padding: 0;
                        text-transform: uppercase;

                        @include media-breakpoint-down(md) {
                            + li {
                                margin-top: 1rem;
                            }
                        }

                        a, span, i {
                            color: $dark-gray;

                            @include media-breakpoint-down(md) {
                                color: $white;
                            }
                        }

                        a, span {
                            font-weight: 500;
                        }

                        > a {
                            display: inline-block;
                            position: relative;

                            @include media-breakpoint-up(xl) {
                                padding: .5rem 2rem;
                            }

                            @include media-breakpoint-down(lg) {
                                padding: .5rem 1rem;
                            }

                            @include media-breakpoint-down(md) {
                                width: 100%;
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }


                        // If this menu item has a sub-menu
                        &.menu-item-has-children {

                            // If this item is currently active => show it
                            &.current_page_parent, &.current_page_ancestor {

                                // Active-Border
                                > a:after {
                                    content: $icon--chevron-down;
                                    font-family: $icomoon-font-family;
                                    font-size: .75rem;
                                    font-weight: normal;
                                    color: $primary;
                                    position: absolute;
                                    top: 50%;
                                    right: .75rem;
                                    transform: translateY(-50%);
                                }
                            }

                            //&.current-menu-item:hover, &.current_page_parent:hover, &.current_page_ancestor:hover {
                            &.is-active-menu_item,
                            &:hover {
                                // Show the sub-menu
                                > ul.sub-menu {
                                    display: flex;
                                    flex-direction: column;
                                    z-index: 999;
                                }
                            }

                            > ul.sub-menu {
                                display: none;
                                position: absolute;
                                top: 100%;
                                left: 2rem;
                                background-color: $dark-gray;
                                width: calc(100% - 4rem);
                                margin: 0;
                                padding: 1.5rem 0;
                                list-style: none;

                                ul.sub-menu {
                                    list-style: none;

                                    @include media-breakpoint-up(lg) {
                                        display: none;
                                    }
                                }

                                @include media-breakpoint-down(md) {
                                    padding: 0;
                                    position: relative;
                                    left: 1rem;
                                    background-color: transparent;
                                    width: 100%;
                                }

                                li {
                                    color: $white;
                                    margin: 0;
                                    text-transform: none;
                                    font-weight: $font-weight-light;

                                    &:hover {
                                        @include media-breakpoint-up(lg) {
                                            color: $primary;
                                            background-color: rgba(255, 255, 255, .15);
                                        }
                                    }

                                    + li {
                                        margin: 0;
                                    }

                                    a, span, i {
                                        color: inherit;
                                        font-weight: inherit;
                                    }

                                    @include media-breakpoint-down(md) {
                                        &.current-menu-item a {
                                            color: $primary;
                                            background-color: $white;
                                        }
                                    }

                                    > a {
                                        display: inline-block;
                                        padding: .5rem 2rem;
                                        width: 100%;

                                        @include media-breakpoint-down(md) {
                                            padding: .5rem 1rem;
                                        }

                                        &:before {
                                            content: $icon--arrow-right;
                                            font-family: $icomoon-font-family;
                                            font-size: .5rem;
                                            font-weight: normal;
                                            color: inherit;
                                            margin-right: .625rem;
                                        }
                                    }
                                }

                                > li.show-children > ul.sub-menu {
                                    display: block;

                                    li:hover {
                                        background: transparent !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--simplified {
        padding: 4rem 0;
    }
}
