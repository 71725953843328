// Remove the default gap
.elementor-column-gap-default > .elementor-row > .elementor-column:not(.padded) > .elementor-column-wrap.elementor-element-populated {
    padding: 0;
}

.elementor-column-gap-default > .elementor-row > .elementor-column.padded > .elementor-element-populated {
    padding: 2rem;
}

body {
    .elementor-widget:not(:last-child) {
        margin-bottom: 0;
    }
}

.elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 1rem;
}


@include media-breakpoint-down(md) {
    .elementor-container.elementor-column-gap-extended, .elementor-container.elementor-column-gap-default {
        padding: 0 1rem;
    }
}

.elementor-container.elementor-column-gap-extended {
    > .elementor-row {
        margin: -1rem;
        width: calc(100% + 2rem);
    }
}

@include media-breakpoint-down(md) {
    .elementor-widget-wrap .elementor-element {
        &.elementor-widget-binect_sections, &.elementor-widget-binect_testimonials, &.elementor-widget-binect_newsletter, &.elementor-widget-binect_news {
            width: calc(100% + 2rem);
            margin-left: -1rem;
            margin-right: -1rem;
        }
    }
}

.elementor-section-wrap .elementor-section.elementor-section-boxed > .elementor-container {
    @extend .container;
}

.elementor-element.elementor-widget-call-to-action {
    border-radius: 30px;

    .elementor-cta__button.elementor-button {
        display: inline-block;
        border-radius: $border-radius-button;
        text-transform: uppercase;

        border: 3px solid $white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
        transition: box-shadow .25s ease-in-out;
        margin-bottom: 10px;

        background-color: $primary;
        color: $white;

        i + span {
            margin-left: .75rem;
        }

        &:hover {
            box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        }
    }
}

@include media-breakpoint-up(md) {
    body:not(.browser--internet-explorer) .elementor-widget-wrap > .elementor-element[data-widget_type="binect_contact_person.default"] {
        height: 100%;
    }
}

//.elementor-element.elementor-widget-heading > .elementor-widget-container > .elementor-heading-title {
//    margin-bottom: 0;
//}

@include media-breakpoint-down(sm) {
    .elementor-row {
        .elementor-column + .elementor-column {
            margin-top: 2rem;
        }
    }

    .elementor-container > .elementor-row > .elementor-column.elementor-element > .elementor-column-wrap.elementor-element-populated {
        margin: 0;
    }

    .elementor > .elementor-inner > .elementor-section-wrap > .elementor-element.elementor-section {
        padding: 0;
    }
}
